import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/now",
    name: "Now",
    component: () =>
      import(/* webpackChunkName: "now" */ "../views/Now.vue")
  },
  {
    path: "/drawing/:id?",
    name: "365 Day Challenge",
    component: () =>
      import(/* webpackChunkName: "drawing" */ "../views/Drawing.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
